import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 4×8\\@70% 1RM`}</p>
    <p>{`Hanging L Hold 4x:20`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 Ascending AMRAP of:`}</p>
    <p>{`2-Deadlifts (225/155)`}</p>
    <p>{`2-Wall Walks (Rx+ 5ft Handstand Walk)`}</p>
    <p>{`4-Deadlifts`}</p>
    <p>{`4-Wall Walks (Rx+ 10ft Handstand Walk)`}</p>
    <p>{`6-Deadlifts`}</p>
    <p>{`6-Wall Walks (Rx+ 15ft Handstand Walk)`}</p>
    <p>{`etc.`}</p>
    <p><em parentName="p">{`*`}{`Handstand Walks are in 5ft increments in your lane starting and
ending with both hands behind the line.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      